import React from 'react'
import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './settings.css'

export default function Settings() {
  return (
    <div className='settingsWrapper'>
        <Topbar />
        <div className="homeBody">
        <div className="sidebar">
            <Sidebar />
        </div>

        <div className="ordersBody">
            <div className="settingsBox">
                 <p className='settingsHeader'>No available settings currently. Contact support.</p>
            </div>
            
            
        </div>

        </div>


    </div>
  )
}
