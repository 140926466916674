import {React, useState, useEffect} from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'
// import './user.css';
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase';

export default function Transaction() {
    const [open, setOpen] = useState(false);
    const [transactionID, setTransactionID] = useState('');
    const [fromNumber,setFromNumber] = useState('');
    const [toNumber, setToNumber] = useState('');
    const [name, setName]=useState("")
    const [purpose, setPurpose] = useState('')
    const [amount, setAmount] = useState('')
    const [transactions,setTransactions] = useState([]);
    const [error,setError] = useState('');
    const [show, setShow] = useState(false)
    const [success, setSuccess] = useState('')
    const [suc,setSuc] = useState('')
    const [results, setResults] = useState('');
    const [reload,setReload] = useState(false);
    const [isSuccess, setIsSuccess] = useState('');


    useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getTransaction').then((res)=>{
            setTransactions(res.data)
            })
       
      },[])

    const handleClose = () => {
        setError('')
        setSuccess("")
        
        setOpen(false);
        window.location.reload(false);
    }

    const handleOpen = (event) => {
      
        setOpen(true);
      }



    

      const submitTransaction = () =>{
        if(toNumber == '' || fromNumber == '' || name == '' || purpose == '' || amount == ''){
            setError('All fields are mandatory')
        }
        else{
            const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

            let result = ' ';
            const charactersLength = characters.length;
            for ( let i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));

            }
            setResults(result)
            setTransactionID(result)
            setSuc('Please enter your mpesa pin on prompt sent to your phone. Ensure you have enough funds for successful transaction')

           
           

        Axios.post('https://adminapi.albatroxlogistics.com/api/insertTransaction',{
            transactionID : result,
            toNumber : toNumber,
            fromNumber : fromNumber, 
            name : name,
            amount: amount,
            purpose : purpose,
            date : Date.now().toString(),
        })        

    }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
          setSuccess(suc)
          
        }, 2000)
    
        return () => clearTimeout(timeout)
    
      }, [results])



      useEffect(() => {
        const timeout = setTimeout(() => {
          setIsSuccess('Transaction successful')
          setSuccess('');
      
        }, 35000)
    
        return () => clearTimeout(timeout)
    
      }, [reload])

    
  return (
    <div className='storeWrapper'>
        {/* ADD TRANSACTION*/}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling2'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>Make Payment</h4>
           
            <p style={{fontSize:'12px', color:'red'}}>{success}</p>
            <p style={{fontSize:'12px', color:'green'}}>{isSuccess}</p>
            <p style={{fontSize:'12px',color:'red'}}>{error}</p>
          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div className="inputField">
           <TextField onChange={(e)=> setFromNumber(e.target.value)} type='text' id="standard-basic2" label="Your MPESA Number*" variant="standard" />
           <br />
           <TextField onChange={(e)=> setToNumber(e.target.value)} type='text' id="standard-basic2" label="Recipient Mpesa Number*" variant="standard" />
           <br />
           <TextField onChange={(e)=> setAmount(e.target.value)} type='text' id="standard-basic2" label="Amount*" variant="standard" />
           <br />
           <TextField onChange={(e)=> setName(e.target.value)} type='text' id="standard-basic2" label="Recipient Name*" variant="standard" />
           <br />
           <select onChange={(e)=> setPurpose(e.target.value)} name="" id="" className='userselect'>
                <option > - Payment purpose - </option>
                <option value="Rider Payment">Rider Payment</option>
                <option value="Merchandise Purchase">Merchandise Purchase</option>
           </select>
           </div>
           <div className="assignButtons">
                <button onClick={submitTransaction} className='addStoreButton'>Initiate Payment</button>
                <button className='addStoreButton2' onClick={handleClose}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>

        








        <Topbar />
        <div className="homeBody">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="ordersBody">
                <div className="storesTable">
                    <p className='storesTitle'>Easybox Payments</p>
                   
                <div className="addButton">
                    <button onClick={handleOpen} className='addStoreButton'>Make Payments</button>
                </div>
                

                <div className="unplannedOrders">
            <br />
            <p className='unplannedHeading'>Recent Transactions</p>
           
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>Transaction ID</th>
                        <th className='tHeading'>Recipient Name</th>
                        <th className='tHeading'>Recipient Number</th>
                        <th className='tHeading'>Amount</th>
                        <th className='tHeading'>Purpose</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Time</th>

                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {transactions.map((val) => {
                                        var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.date)
                                        var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.date)
                   
                    return(

                        <tr>
                            {/* <button onClick={handleOpen} className='tbutton'  value={val.orderNo} >{val.orderNo}</button> */}
                           <td className='tHeading2'>{val.transactionID}</td>
                            
                            <td className='tHeading2'>{val.Name}</td>
                            <td className='tHeading2'>{val.toNumber}</td>
                            <td className='tHeading2'>{val.amount}</td>
                            <td className='tHeading2'>{val.purpose}</td>
                            <td className='tHeading2'>{date3}</td>
                            <td className='tHeading2'>{date4}</td>
                            
                            
                            
                            {/* <td className='tHeading2'>{date4}</td> */}
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>
                </div>
            </div>
        </div>

    </div>
  )
}
