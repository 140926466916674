import {React,useEffect,useState} from 'react'
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Axios from 'axios'
import './reports.css';
import Sidebar from '../../components/sidebar/Sidebar'
import Topbar from '../../components/topbar/Topbar'


export default function Reports() {
    const [rider,setRider] = useState('')
    const [mall, setMall] = useState('')
    const [date, setDate] = useState('')
    const [date2, setDate2] = useState('');
    const [allOrders,setAllOrders] = useState([]);
    const[loggedIn,setLoggedIn] = useState('')
    const[riders,setRiders] = useState([])
    const[stores,setStores] = useState([])

 

    useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllOrders').then((res)=>{
            setAllOrders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getStores').then((res)=>{
            setStores(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getRiders').then((res)=>{
            setRiders(res.data)
            })
      },[])

    
  return (
    <div className='storeWrapper'>
    <Topbar />
    <div className="homeBody">
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="mainBody">
            <div className="storesTable">
                <p className='storesTitle'>Download Reports</p>

            <div className="unplannedOrders3">
        <br />
        <p className='unplannedHeading'>SELECT ORDERS</p>
        <div className="calender">
                <label htmlFor="date1">From:</label>
                <input className="inputDate1" id="date1" type='date' onChange={(e)=>{setDate(e.target.value)}} />
                <label htmlFor="date2">To:</label>
                <input className="inputDate1" id="date2" type='date' onChange={(e)=>{setDate2(e.target.value)}} />
                <label htmlFor="mall">Store</label>
                <select className="inputDate1" name="mall" id="mall" onChange={(e)=>{setMall(e.target.value)}}>
                    <option value="sel">-Select Store-</option>
                    <option value="All">All</option>
                    
                    {stores.map((val,index) => {
                    
                    return(

                        <option className='store'  value={val.storename}>{val.storename}</option>
                    )
                })}

                </select>
                <label htmlFor="rider">Rider</label>
                <select className="inputDate1" name="rider" id="rider" onChange={(e)=>{setRider(e.target.value)}}>
                    <option value="sel">-Select Rider-</option>
                    <option value="All">All</option>
                   
                    {riders.map((val,index) => {
                    
                    return(

                        <option className='store'  value={val.ridermail}>{val.ridername}</option>
                    )
                })}
                </select>
               
            </div>

            <div style={{display:'flex'}}>
            <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="downloadBtn"
            table="table-to-xls"
            filename="EasyBox Logistics Report"
            sheet="New Orders Report"
            buttonText="EXPORT"/>

            </div>
       
        <div className="table">
        <Table id="table-to-xls" className="table">
                <thead>
                    <tr>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Order#</th>
                        <th className='tHeading'>Outlet</th>
                       
                        <th className='tHeading'>Rider</th>
                        <th className='tHeading'>Area</th>
                        <th className='tHeading'>Landmark</th>
                        <th className='tHeading'>PostTime</th>
                        <th className='tHeading'>Dispatched</th>
                        <th className='tHeading'>Delivered</th>
                        <th className='tHeading'>TotalTime</th>
                        <th className='tHeading'>Distance(KM)</th>
                        <th className='tHeading'>Amount(Ksh)</th>
                        <th className='tHeading'>Status</th>
                    </tr>
                </thead>

                <tbody>
                {allOrders.map((val) => {
                     var dte = parseInt(val.dispatchTime);
                     var d1 = new Date(dte);
                     var total;
                     const timestamp1 = Date.parse(d1);
                     const timestamp2 = Date.parse(date);
                     const timestamp3 = Date.parse(date2)

                     var d= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.assignTime)
                     var d2= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.dispatchTime)
                     var d3= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.deliveryTime)
                    //  d4= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(row.data.DeliveryTime)
                     var d10= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.postTime)
                     var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)

                     if(val.deliveryTime == null || val.dispatchTime == null ){
                        
                        total = 'Pending'
                    }
                    else{
                        var d5 = (val.deliveryTime) - (val.dispatchTime)
                        total = new Date(d5).toISOString().slice(11, 19)
                    }
                     
                  

                    if(timestamp1 >= timestamp2 && timestamp1 <= timestamp3){
                        if(val.store == mall){
                            if(val.rider == rider){ 
                                return(
                                    <tr>
                                        <td className='tHeading3'>{date3}</td>
                                         <td className='tHeading3'>{val.orderNo}</td>
                                        <td className='tHeading3'>{val.store}</td>
                                        
                                        <td className='tHeading3'>{val.rider}</td>
                                        <td className='tHeading3'>{val.location}</td>
                                        <td className='tHeading3'>{val.landmark}</td>
                                        <td className='tHeading3'>{d10}</td>
                                
                                        <td className='tHeading3'>{d2}</td>
                                        <td className='tHeading3'>{d3}</td>
                                        <td className='tHeading3'>{total}</td>
                                        <td className='tHeading3'>{val.distance}</td>
                                        <td className='tHeading3'>{val.amount}</td>
                                        <td className='tHeading3'>{val.status}</td>
                                    </tr>
                                    )
                            }
                            else if(rider=='All'){
                                return(
                                    <tr>
                                        <td className='tHeading3'>{date3}</td>
                                         <td className='tHeading3'>{val.orderNo}</td>
                                        <td className='tHeading3'>{val.store}</td>
                                      
                                        <td className='tHeading3'>{val.rider}</td>
                                        <td className='tHeading3'>{val.location}</td>
                                        <td className='tHeading3'>{val.landmark}</td>
                                        <td className='tHeading3'>{d10}</td>
                                      
                                        <td className='tHeading3'>{d2}</td>
                                        <td className='tHeading3'>{d3}</td>
                                        <td className='tHeading3'>{total}</td>
                                        <td className='tHeading3'>{val.distance}</td>
                                        <td className='tHeading3'>{val.amount}</td>
                                        <td className='tHeading3'>{val.status}</td>
                                    </tr>
                                    )

                                }
                        }
                        else if(mall == 'All'){
                            if(val.rider == rider){ 
                                return(
                                    <tr>
                                        <td className='tHeading3'>{date3}</td>
                                      <td className='tHeading3'>{val.orderNo}</td>
                                        <td className='tHeading3'>{val.store}</td>
                                        
                                        <td className='tHeading3'>{val.rider}</td>
                                        <td className='tHeading3'>{val.location}</td>
                                        <td className='tHeading3'>{val.landmark}</td>
                                        <td className='tHeading3'>{d10}</td>
                                   
                                        <td className='tHeading3'>{d2}</td>
                                        <td className='tHeading3'>{d3}</td>
                                        <td className='tHeading3'>{total}</td>
                                        <td className='tHeading3'>{val.distance}</td>
                                        <td className='tHeading3'>{val.amount}</td>
                                        <td className='tHeading3'>{val.status}</td>
                                    </tr>
                                    )
                            }
                            else if(rider=='All'){
                                return(
                                    <tr>
                                        <td className='tHeading3'>{date3}</td>
                                        <td className='tHeading3'>{val.orderNo}</td>
                                        <td className='tHeading3'>{val.store}</td>
                                      
                                        <td className='tHeading3'>{val.rider}</td>
                                        <td className='tHeading3'>{val.location}</td>
                                        <td className='tHeading3'>{val.landmark}</td>
                                        <td className='tHeading3'>{d10}</td>
                                        
                                        <td className='tHeading3'>{d2}</td>
                                        <td className='tHeading3'>{d3}</td>
                                        <td className='tHeading3'>{total}</td>
                                        <td className='tHeading3'>{val.distance}</td>
                                        <td className='tHeading3'>{val.amount}</td>
                                        <td className='tHeading3'>{val.status}</td>
                                    </tr>
                                    
                                    )
                                    
                                }

                        }
                    

                    }


                 
                  })}

    
    
            </tbody>
            </Table>
           
        </div>
       </div>
            </div>
        </div>
    </div>

</div>
  )
}

