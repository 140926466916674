import {React,useState,useEffect }from 'react'
import PropTypes from 'prop-types';
import Axios from 'axios'
import './orders.css'

export default function Transit(props) {
    const [selectedRider, setSelectedRider] = useState("")
    const [riders,setRiders] = useState([])
    const [trips,setTrips] = useState('')
    const [rnumber,setRnumber] = useState('')
    const [error,setError] = useState("")
    const [store,setStore] = useState('')
    const [customerName,setCustomername] = useState('')
    const [customerContact, setCustomercontact] = useState('')
    const [location, setLocation] = useState('')
    const [landmark,setLandmark] = useState('')
    const [special,setSpecial] = useState('')
    const orderno = props.orderno;


    function reassign() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        if(selectedRider === ''){
          setError('Please select the rider.')
      }
      else{
          Axios.put('https://adminapi.albatroxlogistics.com/api/reassignRider',{
              rider : selectedRider,
              orderNo:props.orderno
          })
          Axios.post('https://nod3.brane360.com/webhook/2165985c-a01a-4600-81f2-82574b60d9dd',{
              orderNo: props.orderno,
              store:store,
              ridernumber:rnumber
            })
          
          window.location.reload(false);
        }

      }

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getSelectedRider?rider='+selectedRider).then((res)=>{
            setRnumber(res.data.ridernumber)
            })
       
      },[selectedRider])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getRiders').then((res)=>{
            setRiders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getOrderStore?orderno='+orderno).then((res)=>{
            setStore(res.data.store)
            setCustomername(res.data.customerName)
            setCustomercontact(res.data.customerContact)
            setSpecial(res.data.special)
            setLocation(res.data.location)
            setLandmark(res.data.landmark)
            })
       
      },[])

      function deliverOrder() {
        // setOrderId(event.target.value)
        // console.log(event.target.value)
      
        Axios.put('https://adminapi.albatroxlogistics.com/api/deliverOrder',{
            
            deliveredTime : Date.now().toString(),
            
            orderNo:props.orderno
            
        })
  
        window.location.reload(false);

      }


  return (
    <div className='dispatchContainer'>
         

         
            <p>{error}</p>
            <div className="details">
            <p><strong>Customer:</strong> {customerName}</p>
            <p><strong>Phone:</strong> {customerContact}</p>
            <p><strong>Special Instrustions:</strong> {special}</p>
            <p><strong>Location:</strong> {location}</p>
            <p><strong>Landmark:</strong> {landmark}</p>


           </div>
        
            
          <select className='riderSelect' name="" id="" onChange={(e)=>{setSelectedRider(e.target.value)}}>
            <option>-Reassign Rider-</option>
            {riders.map((val) => {
                    
                    return(
                        <>
                       
                        <option value={val.ridermail}>{val.ridername}</option>
                        </>
                    )
                })}

            </select>
           
          
            
            <div className='assignButtons'>
            <button onClick={deliverOrder} className='assignBTN'>Mark as Delivered</button>
            <button onClick={reassign} className='cancelOrderBTN' >Reassign Order</button>

         

            </div>
          

    </div>
  )
}
Transit.propTypes = {
    click: PropTypes.any,
  };

