import {React,useState,useEffect} from 'react'
import PropTypes from 'prop-types';
import Axios from 'axios'
import './orders.css'


export default function Riderdetails(props) {
    const[riderDetails,setRiderDetails] = useState([]);

    let rider = props.thisRider;

    useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getSpecificRiderByEmail?email='+rider).then((res)=>{
            setRiderDetails(res.data)
            })
       
      },[rider])


  return (
    <div className='dispatchContainer'>
          {riderDetails.map((val) => {
            return(
              <div>
                <p className='modalOrder'>Name: {val.ridername}</p>
                <p  className='modalOrder'>Phone: {val.ridernumber}</p>
                <p  className='modalOrder'>Email: {val.ridermail}</p>
              </div>
            )
          })}
          

    </div>
  )
}

Riderdetails.propTypes = {
    click: PropTypes.any,
  };

