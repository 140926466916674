
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login'
import Home from './pages/home/Home';
import Orders from './pages/orders/Orders';
import Store from './pages/stores/Store';
import Riders from './pages/riders/Riders';
import Users from './pages/users/Users';
import Transaction from './pages/transactions/Transaction';
import Reports from './pages/reports/Reports';
import Settings from './pages/settings/Settings';
import Help from './pages/help/Help';
import Faq from './pages/faq/Faq';
import Adminme from './pages/admin/Adminme';
import Calculate from './pages/admin/Calculate';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
         
          <Route path='/' element={<Login />} />
          <Route path='/home' element={<Home />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/stores' element={<Store />} />
          <Route path='/riders' element={<Riders />} />
          <Route path='/users' element={<Users />} />
          <Route path='/payments' element={<Transaction />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/help' element={<Help />} />
          <Route path='/faqs' element={<Faq />} />
          <Route path='/adminme' element={<Adminme />} />
          <Route path='/calculate' element={<Calculate/>} />

          
         

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
