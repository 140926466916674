import {React, useState, useEffect} from 'react'
import './home.css'
import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'; 
import Axios from 'axios'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    Filler,
  } from 'chart.js';
  import { Line , Pie, Bar } from 'react-chartjs-2';
  import { faker } from '@faker-js/faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    ArcElement,
  );

  //Store performance bar
  
  export const options3 = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right'
      },
      title: {
        display: true,
        text: 'Rider Orders',
      },
    },
  };
  const labels2 = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  

  //Stores graph
   
  export const data4 = {
    labels: ['Abraham', 'Olima', 'Solomoon'],
    datasets: [
      {
        label: 'Delivered Orders',
        data: [200,120,80],
        borderColor: 'green',
        backgroundColor: 'green',
      },
      {
        label: 'Returned Orders',
        data: [10,0,0],
        borderColor: 'blue',
        backgroundColor: 'blue',
      },
      {
        label: 'Cancelled Orders',
        data: [1,0,0],
        borderColor: 'red',
        backgroundColor: 'red',
      },
    ],
  };
  

  
  

  
  

export default function Home() {
    const[monthlyOrders, setMonthlyOrders] = useState([]);
    const[allOrders, setAllOrders] = useState([]);
    const[monthDelivered,setMonthDeliveredOrders] = useState([]);
    const[allDeliveredOrders, setAllDeliveredOrders] = useState([]);
    const[monthReturnedOrders,setMonthReturnedOrders] = useState([]);
    const[allReturnedOrders, setAllReturnedOrders] = useState([]);
    const[monthCancelledOrders,setMonthCancelledOrders] = useState([]);
    const[allCancelledOrders,setAllCancelledOrders] = useState([]);
    const[decemberOrders,setDecemberOrders] = useState([]);
    const[fastOrders,setFastOrders] = useState([]);
    const[mediumOrders,setMediumOrders] = useState([]);
    const[slowOrders,setSlowOrders] = useState([]);
    const[prestigeDelivered,setPrestigeDelivered] = useState([]);
    const[prestigeReturned,setPrestigeReturned] = useState([]);
    const[prestigeCancelled,setPrestigeCancelled] = useState([]);
    const[kilimaniDelivered,setKilimaniDelivered] = useState([]);
    const[kilimaniReturned,setKilimaniReturned] = useState([]);
    const[kilimaniCancelled,setKilimaniCancelled] = useState([]);
    const[waterfrontDelivered,setWaterfrontDelivered] = useState([]);
    const[waterfrontReturned,setWaterfrontReturned] = useState([]);
    const[waterfrontCancelled,setWaterfrontCancelled] = useState([]);
    const[totalSales,setTotalSales] = useState('');
    const[highestSales,setHighestSales] = useState('');
    const[lowestSales,setLowestSales] = useState('');
    const[decemberSales,setDecembersales] = useState('')
    const[januaryOrders,setJanuaryorders] = useState([]);
    const[februaryOrders,setFebruaryorders] = useState([]);
    const[marchOrders,setMarchOrders] = useState([]);
    const[aprilOrders, setAprilOrders] = useState([]);
    const[februarySales, setFebruarysales] = useState('');
    const[marchSales, setMarchSales] = useState('');
    const[aprilSales, setAprilSales] = useState('');
    const[maySales, setMaySales] = useState('');
    const[mayOrders,setMayorders] = useState('');



    useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMonthOrders').then((res)=>{
            setMonthlyOrders(res.data)
            })
       
      },[])


      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllOrders').then((res)=>{
            setAllOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMonthDeliveredOrders').then((res)=>{
            setMonthDeliveredOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllDeliveredOrders').then((res)=>{
            setAllDeliveredOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMonthReturnedOrders').then((res)=>{
            setMonthReturnedOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllReturnedOrders').then((res)=>{
            setAllReturnedOrders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMonthCancelledOrders').then((res)=>{
            setMonthCancelledOrders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAllCancelledOrders').then((res)=>{
            setAllCancelledOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getDecemberOrders').then((res)=>{
            setDecemberOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getJanuaryOrders').then((res)=>{
            setJanuaryorders(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getFebruaryOrders').then((res)=>{
            setFebruaryorders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMarchOrders').then((res)=>{
            setMarchOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAprilOrders').then((res)=>{
            setAprilOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMayOrders').then((res)=>{
            setMayorders(res.data)
            })
       
      },[])


      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/get1hrOrders').then((res)=>{
            setFastOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/get1hr30minOrders').then((res)=>{
            setMediumOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAfter1hr30minOrders').then((res)=>{
            setSlowOrders(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getPrestigeDeliveredOrders').then((res)=>{
            setPrestigeDelivered(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getPrestigeReturnedOrders').then((res)=>{
            setPrestigeReturned(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getPrestigeCancelledOrders').then((res)=>{
            setPrestigeCancelled(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getKilimaniDeliveredOrders').then((res)=>{
            setKilimaniDelivered(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getKilimaniReturnedOrders').then((res)=>{
            setKilimaniReturned(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getKilimaniCancelledOrders').then((res)=>{
            setKilimaniCancelled(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getWaterfrontDeliveredOrders').then((res)=>{
            setWaterfrontDelivered(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getWaterfrontReturnedOrders').then((res)=>{
            setWaterfrontReturned(res.data)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getWaterfrontCancelledOrders').then((res)=>{
            setWaterfrontCancelled(res.data)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getTotalAmount').then((res)=>{
            setTotalSales(res.data.totalAmount)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getJanuarySales').then((res)=>{
            setHighestSales(res.data.highestAmount)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getFebruarySales').then((res)=>{
            setFebruarysales(res.data.highestAmount)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMarchSales').then((res)=>{
            setMarchSales(res.data.highestAmount)
            })
       
      },[])
      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getAprilSales').then((res)=>{
            setAprilSales(res.data.highestAmount)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getMaySales').then((res)=>{
            setMaySales(res.data.highestAmount)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getDecemberSales').then((res)=>{
            setDecembersales(res.data.highestAmount)
            })
       
      },[])

      useEffect(() =>{
        Axios.get('https://adminapi.albatroxlogistics.com/api/getLowestSales').then((res)=>{
            setLowestSales(res.data.lowestAmount)
            })
       
      },[])

      

      //Overview line graph
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Orders',
      },
    },
  };

  
  const labels = ['November','December', 'January', 'February', 'March', 'April', 'May', 'June','July','Aug','Sep'];
  
  const data = {
    labels,
    datasets: [
    
      {
        label: 'orders',
        data: [0,decemberOrders.length,januaryOrders.length,februaryOrders.length,marchOrders.length,aprilOrders.length,mayOrders.length,monthlyOrders.length,0,0,0],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  const data2 = {
    labels: ['45 min', 'in 1hr 30min', 'After 1hr 30min', 'Rejected'],
    datasets: [
      {
        label: '# of Orders',
        data: [((fastOrders.length/allOrders.length)*100), ((mediumOrders.length-fastOrders.length)/allOrders.length)*100, (slowOrders.length/allOrders.length)*100, 0,],
        backgroundColor: [
          'green',
          'blue',
          'yellow',
          'red',

        ],
        borderColor: [
          'green',
          'blue',
          'yellow',
          'red',

        ],
        borderWidth: 1,
      },
    ],
  };

  //Store graph
const options2 = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right'
      },
      title: {
        display: true,
        text: 'Store Orders',
      },
    },
  };
const data3 = {
    labels: ['Naivas Prestige', 'Naivas Kilimani', 'Naivas Waterfront'],
    datasets: [
      {
        label: 'Delivered Orders',
        data: [prestigeDelivered.length,kilimaniDelivered.length,waterfrontDelivered.length],
        borderColor: 'green',
        backgroundColor: 'green',
      },
      {
        label: 'Returned Orders',
        data: [prestigeReturned.length,kilimaniReturned.length,waterfrontReturned.length],
        borderColor: 'blue',
        backgroundColor: 'blue',
      },
      {
        label: 'Cancelled Orders',
        data: [prestigeCancelled.length,kilimaniCancelled.length,waterfrontCancelled.length],
        borderColor: 'red',
        backgroundColor: 'red',
      },
    ],
  };


  //Income graph
  const options4 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales in Ksh',
      },
    },
  };
  
  
  
  const data5 = {
    labels:['Nov','Dec', 'Jan','Feb','Mar','Apr','May'],
    datasets: [
      {
        fill: true,
        label: 'Total Sales',
        data: [0,decemberSales,highestSales,februarySales,marchSales,aprilSales,maySales],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return (
    <div className='homeWrapper'>
        <div className="top">
        <Topbar />
        </div>
        
        

        <div className="homeBody">
            <div className="sidebar">
            <Sidebar />

            </div>
            <div className="mainBody">
            <br />
                <p className='dashboardTitle'>Dashboard</p>
                <div className="topSats">
                    <div className="box1">
                        <p className='monthOrders'>Orders this Month</p>
                        <p className='monthTotal'>{monthlyOrders.length}</p>
                        <p className='totals'>Total Orders: {allOrders.length} </p>


                    </div>
                    <div className="box1">
                        <p className='monthOrders'>Delivered Orders</p>
                        <p className='monthTotal'>{monthDelivered.length}</p>
                        <p className='totals'>Total Delivered Orders: {allDeliveredOrders.length}  </p>


                    </div>
                    <div className="box1">
                        <p className='monthOrders'>Returned Orders</p>
                        <p className='monthTotal'>{monthReturnedOrders.length}</p>
                        <p className='totals'>Total Returned Orders: {allReturnedOrders.length}  </p>

                    </div>
                    <div className="box1">
                        <p className='monthOrders'>Cancelled Orders</p>
                        <p className='monthTotal'>{monthCancelledOrders.length}</p>
                        <p className='totals'>Total Cancelled Orders: {allCancelledOrders.length}  </p>
                    </div>
                </div>

                <div className="bottomStats">
                <div className="bottomLeft">
                    <div className="overview">
                        <div className="overviewTop">
                            <div className="overviewLeft">
                                <p>Overview</p>
                            </div>
                            <div className="overviewRight">
                                <button>Weekly</button>
                                <button style={{backgroundColor:'gray',color:'white'}}>Monthly</button>
                                <button>Yearly</button>
                            </div>
                        </div>
                        
                        <Line options={options} data={data} />
                    </div>
                    <div className="performance">
                        <div className="stores">
                            <p>Store Performance</p>
                            
                        <Bar options={options2} data={data3} />
                        </div>
                        <div className="riders">
                        <p>Riders Performance</p>
                            
                            <Bar options={options3} data={data4} />

                        </div>
                    </div>

                   
                </div>
                <div className="bottomRight">
                    <div className="efficiency">
                        <p>Efficiency(<strong>%</strong>)</p>
                        <Pie data={data2} />

                    </div>
                    <div className="incomes">
                        <p>Incomes(Ksh)</p>
                        <div className="saleStats">
                            <div className="sales">
                                <p className='pAmount'>{totalSales}</p>
                                <p className='psale'>Total Sales</p>
                            </div>
                             <div className="sales">
                                <p className='pAmount'>{marchSales}</p>
                                <p className='psale'>Last Month Sales</p>
                            </div>
                             <div className="sales">
                              {maySales =='' &&<p className='pAmount'>0</p>}
                              {maySales !='' &&<p className='pAmount'>Ksh {maySales}</p>}
                                
                                <p className='psale'>This Month Sales</p>
                            </div>
                        </div>
                        <Line options={options4} style={{height:'700px'}}   data={data5} />

                    </div>

                  
                    
                </div>
            </div>

            </div>
            
        </div>

    </div>
  )
}
