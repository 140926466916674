import React from 'react'
import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'

export default function Help() {
  return (
    <div className='settingsWrapper'>
        <Topbar />
        <div className="homeBody">
        <div className="sidebar">
            <Sidebar />
        </div>
        <div className="ordersBody">
            <div className="settingsBox">
            <p className='settingsHeader'>Contact support. Call 0713199322 or email business@uidevlabs.com</p>
            </div>
           
            
        </div>

        </div>


    </div>
  )
}
